@charset "UTF-8";
:root {
  --colorPrimary: #ddd;
  --colorSecondary: #999;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  line-height: 1.3;
  overflow-x: hidden;
  overflow-y: scroll;
}

p, h1, h2, h3, h4, h5, h6, table, ul, ol, li {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1, h2, h3 {
  line-height: 1.1;
}

pre {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

input, button {
  font-size: inherit;
}

table {
  table-layout: fixed;
  width: 100%;
  max-width: 70em;
}
table th, table td {
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}
table th {
  text-align: left;
}
table th:last-child {
  padding-right: 0;
}
table td:last-child {
  padding-right: 0;
  text-align: right;
}

.table__actions {
  display: flex;
  max-width: 70em;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: space-between;
}
table + .table__actions {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

ul.flat,
ol.flat,
.DisplayList {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}
ul.flat > li,
ol.flat > li,
.DisplayList > li {
  padding-left: 0;
}

dl {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  gap: 0.25em 1em;
}

dt {
  margin: 0;
  grid-column-start: 1;
  align-self: center;
}

dd {
  margin: 0;
  grid-column-start: 2;
}

a {
  color: mediumblue;
  text-decoration: none;
}
a:hover {
  color: navy;
  text-decoration: underline;
}

.field {
  display: block;
  width: 100%;
  max-width: 400px;
  margin-bottom: 0.5rem;
}
.field__wide {
  width: 100%;
}

.FormError {
  color: darkred;
  font-size: 80%;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.flexible {
  flex: 1 1 0px;
}

.flex-spacer {
  flex: 1 1 0px;
}

.flex-grow {
  flex-grow: 1;
}

.relative {
  position: relative;
}

.toolbar {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.toolbar--baseline {
  align-items: baseline;
}
.toolbar > * {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.toolbar > .flex-spacer {
  margin: 0;
}
.toolbar > .toolbar {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.Icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  flex-shrink: 0;
}
.Icon--middle {
  margin-top: -0.125em;
  vertical-align: middle;
}
.Icon--spin {
  animation: linear 1.25s infinite spin-animation;
}

@keyframes placeholder-shimmer {
  0% {
    transform: translateX(-75%);
  }
  100% {
    transform: translateX(0%);
  }
}
.placeholder,
.placeholder-text {
  position: relative;
  overflow: hidden;
}
.placeholder::before,
.placeholder-text::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 400%;
  display: block;
  content: "";
  background: rgba(0, 0, 0, 0.06);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.04) 38%, rgba(0, 0, 0, 0.08) 46%, rgba(0, 0, 0, 0.04) 58%);
  animation: placeholder-shimmer 1s linear infinite;
  will-change: transform;
  pointer-events: none;
}

.placeholder-text {
  display: inline-block;
  width: 75%;
  vertical-align: top;
}
.placeholder-text::before {
  top: 2px;
  bottom: 2px;
}
.placeholder-text:empty::after {
  content: " ";
}

section {
  margin-bottom: 1.5em;
}

main,
.Header {
  padding: 1rem 1rem;
}

main {
  padding-top: 1.5em;
}

.SearchResults--loading .SearchResults__sections::after, .loading-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.loading-overlay {
  position: relative;
}
.Header {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #bbb;
  background: #f3f3f3;
}

.Dropdown {
  position: relative;
  display: inline-flex;
}
.Dropdown__items {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  max-width: 70vw;
  max-height: 60vh;
  margin-top: 1px;
  padding: 0.5rem 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #999;
  background: #f6f6f6;
  white-space: nowrap;
  text-align: left;
  z-index: 100;
}
.Dropdown__header {
  margin: 0 0 0.5rem;
}
.Dropdown__option {
  display: block;
  margin: 0;
  padding: 0.25em 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@keyframes status-throb {
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
@keyframes status-throb-inner {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  70% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(0.95);
    opacity: 0;
  }
}
.StatusIndicator,
.StatusIndicator--throbbing::after {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  vertical-align: middle;
  background: currentColor;
  color: #999;
}

.StatusIndicator--ok {
  color: #3d3;
}
.StatusIndicator--error {
  color: #d33;
}
.StatusIndicator--warning {
  color: #eb3;
}
.StatusIndicator--throbbing {
  position: relative;
  animation: status-throb 2s infinite;
}
.StatusIndicator--throbbing::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0;
  color: inherit;
  animation: status-throb-inner 2s infinite;
  z-index: -1;
}

.Nav__item--active {
  text-decoration: underline;
}

.FadedImage {
  opacity: 1;
  will-change: opacity;
  transition: opacity 0.3s linear;
}
.FadedImage--instant {
  transition: unset;
}
.FadedImage--loading, .FadedImage--inactive {
  opacity: 0;
}

.DisplayImage {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 3em;
  height: 3em;
  border-radius: 0.25em;
  color: #fff;
  background-size: cover;
  background-color: var(--colorPrimary);
  overflow: hidden;
}
.DisplayImage--Artist {
  border-radius: 100%;
}
.DisplayImage--Artist:not(.DisplayImage--thumbnail), .DisplayImage--Artist:not(.DisplayImage--thumbnail) .DisplayImage__image {
  border: 2px solid var(--colorPrimary);
}
.DisplayImage--Artist:not(.DisplayImage--thumbnail) .DisplayImage__image {
  border-radius: 100%;
  border-color: #fff;
}
.DisplayImage--normal {
  font-size: 3rem;
}
.DisplayImage__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.DisplayImage__icon {
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 20%;
  right: 20%;
  width: 60%;
  height: 60%;
  display: block;
  opacity: 0.5;
}

.DisplayItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  max-width: 100%;
  margin-bottom: 0;
}
.DisplayItem--list {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid #ddd;
  border-width: 1px 0;
  margin-top: -1px;
}
.DisplayItem--tile {
  flex-direction: column;
  flex: 1 1 6rem;
  min-width: 6rem;
  border-radius: 0.5rem;
  text-align: center;
}
.DisplayItem:link, .DisplayItem:hover {
  text-decoration: none;
}
.DisplayItem:target .DisplayItem__title {
  font-weight: bold;
}
.DisplayItem .DisplayImage {
  margin-right: 0.5rem;
  flex-shrink: 0;
}
.DisplayItem--tile .DisplayImage {
  margin: 0 0 0.25rem;
  width: 100%;
  padding-top: 100%;
}
.DisplayItem__text {
  flex-grow: 1;
  min-width: 0;
}
.DisplayItem--tile .DisplayItem__text {
  width: 100%;
}
.DisplayItem__title, .DisplayItem__subtitle {
  width: 100%;
}
.DisplayItem__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}
.DisplayItem__title.placeholder-text {
  max-width: 260px;
}
.DisplayItem__subtitle {
  color: #999;
  text-decoration: none;
  font-size: 0.875em;
}
.DisplayItem__subtitle.placeholder-text {
  width: 60%;
  max-width: 200px;
}
.DisplayItem__text--subtitled > * {
  display: block;
  -webkit-line-clamp: none;
  line-clamp: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.DisplayList {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  /* 
    Determine number of columns to show in DisplayLists based on:
    - list/tile format
    - $breakpoint = breakpoint starting width (pixels)
    - $density = requested density specified by backend
    - XXXpx = component minimum width (pixels)

    This implementation definitely needs to be re-thought, given that it
    currently uses the breakpoint minimum width rather than actual width of the
    grid it's supposed to lay out elements within.

    One possible alternative is to use the native CSS grid.

    The implementation should ideally:
    - Allow forcing single row when desired, hiding any items that overflow - https://stackoverflow.com/questions/48692455/how-to-hide-implicit-grid-rows
    - Allow components to specify min & target widths, with max being optional (only really relevant for tile style)
    - Consider $density to be the maximum number of columns per row
    - Align reasonably with lists/grids of other components
  */
}
.DisplayList--list {
  row-gap: 0;
}
.DisplayList--2 {
  grid-template-columns: repeat(2, 1fr);
}
.DisplayList--3 {
  grid-template-columns: repeat(3, 1fr);
}
.DisplayList--4 {
  grid-template-columns: repeat(4, 1fr);
}
.DisplayList--5 {
  grid-template-columns: repeat(5, 1fr);
}
.DisplayList--6 {
  grid-template-columns: repeat(6, 1fr);
}
.DisplayList--7 {
  grid-template-columns: repeat(7, 1fr);
}
.DisplayList--8 {
  grid-template-columns: repeat(8, 1fr);
}
.DisplayList--9 {
  grid-template-columns: repeat(9, 1fr);
}
.DisplayList--10 {
  grid-template-columns: repeat(10, 1fr);
}
.DisplayList--11 {
  grid-template-columns: repeat(11, 1fr);
}
.DisplayList--12 {
  grid-template-columns: repeat(12, 1fr);
}

.SoundZoneRow > h2 .StatusIndicator {
  font-size: 0.5em;
}

.SoundZoneRow {
  flex-grow: 1;
}
.SoundZoneRow__now-playing {
  width: 100%;
}

.NowPlaying__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}
.NowPlaying__cover {
  width: 10em;
  max-width: 100%;
}

.CopyButton {
  font-size: 0.75rem;
}

.ZoneRecentlyPlayed,
.Tracklist {
  max-width: 50em;
}

.Tracklist__track,
.ZoneRecentlyPlayed__track,
.ZoneRecentlyPlayed__source {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left !important;
}

.Tracklist__track,
.ZoneRecentlyPlayed__track {
  width: 90%;
}

.Tracklist__album,
.ZoneRecentlyPlayed__source {
  width: 70%;
}

.Tracklist__duration,
.ZoneRecentlyPlayed__time {
  text-align: right;
  width: 5em;
}

@media only screen and (max-width: 599px) {
  .ZoneRecentlyPlayed .table__actions {
    flex-wrap: wrap;
  }

  .ZoneRecentlyPlayed__date,
.ZoneRecentlyPlayed__loadMore {
    order: -1;
    width: 100%;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .ZoneRecentlyPlayed__track {
    width: 80%;
  }

  .Tracklist__track .DisplayImage,
.ZoneRecentlyPlayed__track .DisplayImage {
    display: none;
  }

  .Tracklist__album,
.ZoneRecentlyPlayed__source {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 719px) {
  .ZoneRecentlyPlayed__source .DisplayImage {
    display: none;
  }
}
.Album__tracks {
  counter-reset: album;
}
.Album__tracks .DisplayImage--Track {
  counter-increment: album;
}
.Album__tracks .DisplayImage--Track img, .Album__tracks .DisplayImage--Track .Icon {
  display: none !important;
}
.Album__tracks .DisplayImage--Track::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  content: counter(album);
  font-size: 1.25em;
  font-weight: bold;
  text-align: center;
  color: #fff;
}
.Album__copyrights {
  margin-top: -0.5rem;
  color: #999;
}
.Album__copyrights li {
  margin-bottom: 0;
}

.PageHeader__meta {
  flex: 1 1 0px;
}

.UserDropdown__header,
.DebugDropdown .Dropdown__header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.UserDropdown .Dropdown__button {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
}
.UserDropdown__header span {
  min-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.DebugDropdown .Dropdown__items {
  min-width: 0;
}
.DebugDropdown__table td {
  font-size: 12px;
  padding: 0;
}

@media only screen and (min-width: 720px) {
  .PlayButton__toolbar .toolbar {
    flex-wrap: nowrap;
  }
}
.PlayButton__toolbar .toolbar .SearchField__input {
  flex-shrink: 1;
}
.PlayButton__toolbar .toolbar .SearchField__submit {
  display: none;
}

.SearchResults__sections {
  position: relative;
}
@media (max-width: 449px) {
  .Header__spacer {
    min-width: 100%;
  }

  .Zones__search-spacer {
    min-width: 100%;
  }
}
@media (min-width: 450px) {
  .Zones__search-spacer {
    flex-grow: 0;
  }
}
@media (max-width: 499px) {
  .UserDropdown .Dropdown__button {
    max-width: 100px;
  }
  .UserDropdown__logged-in-as {
    display: none;
  }

  .PageHeader {
    justify-content: center;
    text-align: center;
  }
  .PageHeader__meta {
    flex-basis: 100%;
  }
}
@media (min-width: 500px) {
  .Header {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .DebugDropdown .Dropdown__items {
    left: auto;
    right: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
  }
}
/*# sourceMappingURL=index.de2491f0.css.map */
