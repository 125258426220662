@use "sass:math";
@import "mixins";

$space-1: 0.5rem;
$space-2: 1rem;
$subtle: #999;

:root {
  --colorPrimary: #ddd;
  --colorSecondary: #999;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  line-height: 1.3;
  overflow-x: hidden;
  overflow-y: scroll;
}

p, h1, h2, h3, h4, h5, h6, table, ul, ol, li {
  margin-top: 0;
  margin-bottom: $space-1;
}

h1, h2, h3 {
  line-height: 1.1;
}

pre {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

input, button {
  font-size: inherit;
}

table {
  table-layout: fixed;
  width: 100%;
  max-width: 70em;
  th, td {
    padding-right: $space-1;
    padding-bottom: $space-1;
  }
  th { text-align: left; }
  th:last-child { padding-right: 0; }
  td:last-child { padding-right: 0; text-align: right; }
}

.table__actions {
  display: flex;
  max-width: 70em;
  margin-bottom: $space-1;
  align-items: center;
  justify-content: space-between;

  table + & {
    margin-top: $space-1;
    margin-bottom: 0;
  }
}

ul.flat,
ol.flat,
.DisplayList {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  > li {
    padding-left: 0;
  }
}

dl {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  gap: 0.25em 1em;
}
dt {
  margin: 0;
  grid-column-start: 1;
  align-self: center;
}
dd {
  margin: 0;
  grid-column-start: 2;
}

a {
  color: mediumblue;
  text-decoration: none;

  &:hover {
    color: navy;
    text-decoration: underline;
  }
}

.field {
  display: block;
  width: 100%;
  max-width: 400px;
  margin-bottom: $space-1;

  &__wide {
    width: 100%;
  }
}

.FormError {
  color: darkred;
  font-size: 80%;
}

.mt-0 { margin-top: 0; }
.mb-0 { margin-bottom: 0; }
.mt-1 { margin-top: $space-1; }
.mb-1 { margin-bottom: $space-1; }
.mt-2 { margin-top: $space-2; }
.mb-2 { margin-bottom: $space-2; }

.flexible { flex: 1 1 0px; }
.flex-spacer { flex: 1 1 0px; }
.flex-grow { flex-grow: 1; }

.relative {
  position: relative;
}

.toolbar {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -$space-1;
  margin-right: -$space-1;

  &--baseline {
    align-items: baseline;
  }

  > * {
    margin-left: $space-1;
    margin-right: $space-1;
    margin-bottom: $space-1;
  }

  > .flex-spacer {
    margin: 0;
  }

  > .toolbar {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}

@keyframes spin-animation {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

.Icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  flex-shrink: 0;

  &--middle {
    margin-top: -0.125em;
    vertical-align: middle;
  }

  &--spin {
    animation: linear 1.25s infinite spin-animation;
  }
}

@keyframes placeholder-shimmer {
  0% { transform: translateX(-75%); }
  100% { transform: translateX(0%); }
}

.placeholder,
.placeholder-text {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 400%;
    display: block;
    content: '';
    background: rgba(#000, 0.06);
    background: linear-gradient(135deg, rgba(#000, 0.04) 38%, rgba(#000, 0.08) 46%, rgba(#000, 0.04) 58%);
    // background-size: 200% 200%;
    animation: placeholder-shimmer 1s linear infinite;
    will-change: transform;
    pointer-events: none;
  }
}

.placeholder-text {
  display: inline-block;
  width: 75%;
  vertical-align: top; // prevents additional vertical space from being added

  &::before {
    top: 2px;
    bottom: 2px;
  }

  &:empty::after {
    content: '\00a0';
  }
}

section {
  margin-bottom: 1.5em;
}

main,
.Header {
  padding: $space-2 $space-2;
}

main {
  padding-top: 1.5em;
}

%loading-overlay {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#fff, 0.7);
  z-index: 10;
}

.loading-overlay {
  position: relative;

  &::after {
    @extend %loading-overlay;
  }
}

.Header {
  padding-bottom: $space-1;
  border-bottom: 1px solid #bbb;
  background: #f3f3f3;
}

.Dropdown {
  position: relative;
  display: inline-flex;

  &__items {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 200px;
    max-width: 70vw;
    max-height: 60vh;
    margin-top: 1px;
    padding: $space-1 $space-1;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid $subtle;
    background: #f6f6f6;
    white-space: nowrap;
    text-align: left;
    z-index: 100;
  }
  &__header {
    margin: 0 0 $space-1;
  }
  &__option {
    display: block;
    margin: 0;
    padding: 0.25em 0;
    @include truncate-text;
  }
}

@keyframes status-throb {
  0% { transform: scale(0.95); }
  70% { transform: scale(1); }
  100% { transform: scale(0.95); }
}
@keyframes status-throb-inner {
  0% { transform: scale(0.95); opacity: 0.7; }
  70% { transform: scale(2); opacity: 0; }
  100% { transform: scale(0.95); opacity: 0; }
}

.StatusIndicator,
.StatusIndicator--throbbing::after {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  vertical-align: middle;
  background: currentColor;
  color: $subtle;
}

.StatusIndicator {
  &--ok { color: #3d3; }
  &--error { color: #d33; }
  &--warning { color: #eb3; }

  &--throbbing {
    position: relative;
    animation: status-throb 2s infinite;
  }
  &--throbbing::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    color: inherit;
    animation: status-throb-inner 2s infinite;
    z-index: -1;
  }
}

.Nav__item {
  &--active {
    text-decoration: underline;
  }
}

.FadedImage {
  opacity: 1;
  will-change: opacity;
  transition: opacity 0.3s linear;

  &--instant {
    transition: unset;
  }

  &--loading,
  &--inactive {
    opacity: 0;
  }
}

.DisplayImage {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 3em;
  height: 3em;
  border-radius: 0.25em;
  color: #fff;
  background-size: cover;
  background-color: var(--colorPrimary);
  // background-image: linear-gradient(var(--colorPrimary), var(--colorSecondary));
  overflow: hidden; // clip on border-radius

  &--Artist {
    border-radius: 100%;

    &:not(.DisplayImage--thumbnail) {
      &, .DisplayImage__image {
        border: 2px solid var(--colorPrimary);
      }
      .DisplayImage__image {
        border-radius: 100%;
        border-color: #fff;
      }
    }
  }

  &--normal {
    font-size: 3rem;
  }

  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  &__icon {
    position: absolute;
    top: 20%;
    bottom: 20%;
    left: 20%;
    right: 20%;
    width: 60%;
    height: 60%;
    display: block;
    opacity: 0.5;
  }
}

.DisplayItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  // width: 100%;
  min-width: 0;
  max-width: 100%;
  margin-bottom: 0;

  &--list {
    padding-top: $space-1;
    padding-bottom: $space-1;
    border: 1px solid #ddd;
    border-width: 1px 0;
    // Hack to avoid 2px borders between adjacent items, while still ensuring
    // all borders are rendered in multi column layouts
    margin-top: -1px;
  }

  &--tile {
    flex-direction: column;
    flex: 1 1 6rem;
    min-width: 6rem;
    border-radius: 0.5rem;
    text-align: center;
  }

  &:link, &:hover {
    text-decoration: none;
  }

  &:target &__title {
    font-weight: bold;
  }

  .DisplayImage {
    margin-right: $space-1;
    flex-shrink: 0;
  }

  &--tile .DisplayImage {
    margin: 0 0 0.25rem;
    // width: 6em;
    // height: 6em;
    width: 100%;
    padding-top: 100%;
  }

  &__text {
    flex-grow: 1;
    min-width: 0;
    // TODO: Avoid extending past container in list mode
  }

  &--tile &__text {
    width: 100%;
  }

  &__title,
  &__subtitle {
    width: 100%;
  }

  &__title {
    @include truncate-text(2);

    &.placeholder-text {
      max-width: 260px;
    }
  }

  &__subtitle {
    color: $subtle;
    text-decoration: none;
    font-size: 0.875em;

    &.placeholder-text {
      width: 60%;
      max-width: 200px;
    }
  }

  &__text--subtitled > * {
    @include truncate-text(1, true);
  }
}

.DisplayList {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(1, 1fr);
  gap: $space-2;

  &--list {
    row-gap: 0;
  }

  /* 
    Determine number of columns to show in DisplayLists based on:
    - list/tile format
    - $breakpoint = breakpoint starting width (pixels)
    - $density = requested density specified by backend
    - XXXpx = component minimum width (pixels)

    This implementation definitely needs to be re-thought, given that it
    currently uses the breakpoint minimum width rather than actual width of the
    grid it's supposed to lay out elements within.

    One possible alternative is to use the native CSS grid.

    The implementation should ideally:
    - Allow forcing single row when desired, hiding any items that overflow - https://stackoverflow.com/questions/48692455/how-to-hide-implicit-grid-rows
    - Allow components to specify min & target widths, with max being optional (only really relevant for tile style)
    - Consider $density to be the maximum number of columns per row
    - Align reasonably with lists/grids of other components
  */
  @for $cols from 2 through 12 {
    &--#{$cols} {
      grid-template-columns: repeat($cols, 1fr);
    }
  }
}

.SoundZoneRow > h2 .StatusIndicator {
  font-size: 0.5em;
}

.SoundZoneRow {
  flex-grow: 1;

  &__now-playing {
    width: 100%;
  }
}

.NowPlaying {
  &__title {
    @include truncate-text(2);
  }

  &__cover {
    width: 10em;
    max-width: 100%;
  }
}

.CopyButton {
  font-size: 0.75rem;
}

.ZoneRecentlyPlayed,
.Tracklist {
  max-width: 50em;
}

.Tracklist__track,
.ZoneRecentlyPlayed__track,
.ZoneRecentlyPlayed__source {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left !important;
}
.Tracklist__track,
.ZoneRecentlyPlayed__track {
  width: 90%;
}
.Tracklist__album,
.ZoneRecentlyPlayed__source {
  width: 70%;
}
.Tracklist__duration,
.ZoneRecentlyPlayed__time {
  text-align: right;
  width: 5em;
}

@include to(s) {
  .ZoneRecentlyPlayed .table__actions {
    flex-wrap: wrap;
  }
  .ZoneRecentlyPlayed__date,
  .ZoneRecentlyPlayed__loadMore {
    order: -1;
    width: 100%;
    margin-bottom: $space-1;
    text-align: center;
  }
  .ZoneRecentlyPlayed__track {
    width: 80%;
  }
  .Tracklist__track .DisplayImage,
  .ZoneRecentlyPlayed__track .DisplayImage {
    display: none;
  }
  .Tracklist__album,
  .ZoneRecentlyPlayed__source {
    display: none;
  }
}
@include between(s, m) {
  .ZoneRecentlyPlayed__source .DisplayImage {
    display: none;
  }
}

.Album {
  &__tracks {
    counter-reset: album;

    .DisplayImage--Track {
      counter-increment: album;

      img, .Icon {
        display: none !important;
      }

      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        content: counter(album);
        font-size: 1.25em;
        font-weight: bold;
        text-align: center;
        color: #fff;
      }
    }
  }

  &__copyrights {
    margin-top: - $space-1;
    color: $subtle;
    li {
      margin-bottom: 0;
    }
  }
}

.PageHeader {
  &__meta {
    flex: 1 1 0px;
  }
}

.UserDropdown__header,
.DebugDropdown .Dropdown__header {
  display: flex;
  align-items: center;
  gap: $space-1;
}

.UserDropdown {
  .Dropdown__button {
    @include truncate-text;
    max-width: 150px;
  }

  &__header span {
    min-width: 100px;
    @include truncate-text;
  }
}

.DebugDropdown {
  .Dropdown__items {
    min-width: 0;
  }

  &__table td {
    font-size: 12px;
    padding: 0;
  }
}

.PlayButton {
  &__toolbar .toolbar {
    @include from(m) {
      flex-wrap: nowrap;
    }

    .SearchField__input {
      flex-shrink: 1;
    }

    .SearchField__submit {
      display: none;
    }
  }
}

.SearchResults {
  &__sections {
    position: relative;
  }

  &--loading &__sections::after {
    @extend %loading-overlay;
  }
}

@media (max-width: 449px) {
  .Header__spacer {
    min-width: 100%;
  }

  .Zones__search-spacer {
    min-width: 100%;
  }
}

@media (min-width: 450px) {
  .Zones__search-spacer {
    flex-grow: 0;
  }
}

@media (max-width: 499px) {
  .UserDropdown {
    .Dropdown__button {
      max-width: 100px;
    }

    &__logged-in-as {
      display: none;
    }
  }


  .PageHeader {
    justify-content: center;
    text-align: center;

    &__meta {
      flex-basis: 100%;
    }
  }
}

@media (min-width: 500px) {
  .Header {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .DebugDropdown {
    .Dropdown__items {
      left: auto;
      right: 0;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
    }
  }
}
