@use "sass:math";

$breakpoints: 0 400px 600px 720px 1000px 1280px 1600px;
$scale-names: c  xs     s     m     l      xl    hd;

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }
  @return $number;
}

// Truncate past X lines - works in both chromium and firefox
@mixin truncate-text($lines: 1, $override: false) {
  @if $lines > 1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    line-clamp: $lines;
    @if $override {
      white-space: normal;
    }
  } @else {
    @if $override {
      display: block;
      -webkit-line-clamp: none;
      line-clamp: none;
    }
    white-space: nowrap;
  }
  text-overflow: ellipsis;
  overflow: hidden;
}

@function breakpoint($input, $subtract: false) {
  $value: $input;

  @if type-of($value) == string {
    $value: index($scale-names, $input);
    @if $value {
      $value: $value - 1;
    }
  }
  @if type-of($value) != number {
    @error "breakpoint(#{$input}) unknown breakpoint (#{$value})";
  }
  @if not unitless($value) {
    @return $value;
  }

  @if $value < 1 {
    @return 0;
  } @else if $value > length($breakpoints) {
    @error "breakpoint(#{$input}) outside defined $breakpoints";
  }

  $pixels: nth($breakpoints, $value + 1);
  @if $subtract {
    @return $pixels - 1;
  }
  @return $pixels;
}

// from(X) - from breakpoint X and up
@mixin from($min) {
  $min: breakpoint($min);
  @if $min == 0 {
    @content;
  } @else {
    @media only screen and (min-width: #{$min}) {
      @content;
    }
  }
}

// to(X) - up to (but excluding) breakpoint X
@mixin to($max) {
  $max: breakpoint($max, true);
  @media only screen and (max-width: #{$max}) {
    @content;
  }
}

// between(X, Y) - from breakpoint X up to (but excluding) breakpoint Y
@mixin between($min, $max) {
  $min: breakpoint($min);
  $max: breakpoint($max, true);
  @if $min == 0 {
    @include to($max) { @content; }
  } @else if $max == 0 {
    @include from($min) { @content; }
  } @else {
    @media only screen and (min-width: #{$min}) and (max-width: #{$max}) {
      @content;
    }
  }
}
